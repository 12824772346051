import React, {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List} from "@mui/material";
import InfoModal from "../InfoModal/InfoModal";
import {useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const MissingInfo = ({open, handleClose}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const restaurants = useSelector(
        (state) => state.restaurantsReducer.restaurants
    );


    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (e, restaurant) => {
        e.stopPropagation();
        setShowModal(true);
        setModalContent(restaurant);
    };

    // Sorting restaurants alphabetically by name
    const sortedRestaurants = [...restaurants].sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    const getMissingInfo = (restaurant) => {

        let missingInfo = [];

        if (!Object.hasOwn(restaurant, 'placeId')) {
            missingInfo.push('placeId');
        } else if (!Object.hasOwn(restaurant, 'reels')) {
            missingInfo.push('reels');
        }

        for (const [key, value] of Object.entries(restaurant)) {
            if ((key === 'placeId' && (!value || value.trim().length === 0)) ||
                (key === 'reels' && (!value || value.length === 0))) {
                    missingInfo.push(key);
            }
        }

        if (missingInfo.length === 0) {
            return null;
        }

        return (
            <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Button sx={{color: 'blue'}} onClick={(e) => handleItemClick(e, restaurant)}>
                {restaurant.name}
            </Button>
            </AccordionSummary>
            <AccordionDetails>
            <ul>
                {missingInfo.map((info) => (
                    <li key={info}>{info}</li>
                ))}
            </ul>
            </AccordionDetails>
        </Accordion>
        )
    }
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="Restaurant-directory-title"
            >
                <DialogTitle id="Restaurant-directory-title">
                    Missing Information
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {sortedRestaurants.map((restaurant, index) => (
                            <React.Fragment key={index}>
                                {getMissingInfo(restaurant)}
                            </React.Fragment>
                        ))}
                    </List>
                    <Box
                        sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}
                    >
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {showModal && (
                <InfoModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    modalContent={modalContent}
                />
            )}
        </Fragment>
    );
}
