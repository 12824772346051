import React, {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SignIn from "../SignIn/SignIn";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {logout} from "../../redux/features/auth/authThunks";
import ContactUsForm from "../FormSpreeForms/ContactUs/ContactUs";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteRestaurants from "./FavoriteRestaurants";
import {Searchbar} from "../Search/Searchbar";
import Profile from "../Profile/Profile";
import {RequestRestaurant} from "../RequestRestaurant/RequestRestaurant";
import WishlistRestaurants from "./WishlistRestaurants";
import PendingRestaurants from "../PendingRestaurants/PendingRestaurants";
import {Search, SearchIconWrapper, StyledInputBase} from "./navbar_helpers";
import AboutUs from "../About Us/AboutUs";
import Hidden from "@mui/material/Hidden";
import RestaurantDirectory from "../RestaurantDirectory/RestaurantDirectory";
import BottomMenu from "./BottomMenu";
import {BottomNavigationAction} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {NearbyRestaurants} from "../NearbyRestaurants/NearbyRestaurants";
import { MissingInfo } from "../MissingInfo/MissingInfo";


export default function Navbar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [signInOpen, setSignInOpen] = useState(false);
    const [contactUsOpen, setContactUsOpen] = useState(false);
    const [aboutUsOpen, setAboutUsOpen] = useState(false);
    const [restaurantDirectoryOpen, setRestaurantDirectoryOpen] = useState(false);
    const [favoriteOpen, setFavoriteOpen] = useState(false);
    const [wishlistOpen, setWishlistOpen] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [requestRestaurantOpen, setRequestRestaurantOpen] = useState(false);
    const [pendingRestaurantsOpen, setPendingRestaurantsOpen] = useState(false);
    const [missingInfo, setMissingInfo] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [nearbyRestaurantsOpen, setNearbyRestaurantsOpen] = useState(false);
    const user = useSelector((state) => state.authReducer.user);
    const isAdmin = user?.isAdmin;
    const dispatch = useDispatch();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSearchToggle = () => {
        setSearchVisible(false);
    };

    const handleLogout = () => {
        setAnchorElUser(null);
        dispatch(
            logout(() => {
                toast.success("Logged out successfully");
            })
        );
    };

    const Logo = () => (
        <Hidden mdDown>
            <img
                src="/white-logo.svg"
                alt="Icon"
                style={{
                    maxWidth: "300px", // Resize the image to fit its container width
                    height: "auto", // Maintain aspect ratio
                }}
            />
        </Hidden>
    );

    const MobileLogo = () => (
        <Hidden mdUp>
            <div style={{
                display: 'flex',
                flexGrow: 1, // Allow the container to grow to fill available space
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%' // Ensure the container takes up the full height
            }}>
                <img
                    src="/white-logo-half.svg"
                    alt="Small Icon"
                    style={{
                        maxWidth: "100%", // Resize the image to fit its container width
                        height: "auto", // Maintain aspect ratio
                    }}
                />
            </div>
        </Hidden>
    );

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "#1E1E2F",
                    height: "4.5rem",
                    flexDirection: {xs: 'row'},
                    alignItems: {xs: 'center'}
                }}
            >
                <Toolbar sx={{height: '100%', padding: '0 1rem', margin: '0 auto', width: '100%'}} disableGutters>
                    <div style={{ overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Logo />
                    </div>
                    <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: "block", md: "none"},
                            }}
                        >
                            <MenuItem
                                key={"about-us"}
                                onClick={() => {
                                    setAnchorElNav(false);
                                    setAboutUsOpen(true);
                                }}
                            >
                                <Typography textAlign="center">About Us</Typography>
                            </MenuItem>
                            <MenuItem
                                key={"contact-us"}
                                onClick={() => {
                                    setAnchorElNav(false);
                                    setContactUsOpen(true);
                                }}
                            >
                                <Typography textAlign="center">Contact Us</Typography>
                            </MenuItem>
                            <MenuItem
                                key={"request restaurant"}
                                onClick={() => {
                                    if (user === null) {
                                        setSignInOpen(true);
                                        return;
                                    }
                                    setRequestRestaurantOpen(true);
                                }}
                                >
                                <Typography textAlign="center">Request Restaurant</Typography>
                            </MenuItem>
                            <MenuItem
                                key={"restaurant-directory"}
                                onClick={() => {
                                    setRestaurantDirectoryOpen(true);
                                }}
                            >
                                <Typography textAlign="center">Directory</Typography>
                            </MenuItem>
                            {isAdmin && (
                                    <MenuItem
                                        key={"pending"}
                                        onClick={() => {
                                            setPendingRestaurantsOpen(true);
                                        }}
                                    >
                                        Pending Restaurants
                                    </MenuItem>

                            )}
                            {isAdmin && (
                                   <MenuItem
                                        key={"missing"}
                                        onClick={() => {
                                            setMissingInfo(true);
                                        }}
                                    >
                                        Missing Info
                                    </MenuItem>
                            )}
                        </Menu>
                    </Box>
                    <div style={{ overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center' }}>
                        <MobileLogo/>
                    </div>
                    <Box sx={{marginTop: '0.8rem', flexGrow: 1, display: {xs: "none", md: "flex"}}}>
                        <Button
                            key={"about-us"}
                            onClick={() => {
                                setAboutUsOpen(true);
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >
                            About Us
                        </Button>
                        <Button
                            key={"contact-us"}
                            onClick={() => {
                                setContactUsOpen(true);
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >
                            Contact Us
                        </Button>
                        <Button
                            key={"add-restaurants"}
                            onClick={() => {
                                if (user === null) {
                                    setSignInOpen(true);
                                    return;
                                }

                                setRequestRestaurantOpen(true);
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                            >
                            Request Restaurant
                        </Button>
                        <Button
                            key={"restaurant-directory"}
                            onClick={() => {
                                setRestaurantDirectoryOpen(true);
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >
                            Directory
                        </Button>
                        {isAdmin && (
                            <>
                            <Button
                                key={"pending"}
                                onClick={() => {
                                    setPendingRestaurantsOpen(true);
                                }}
                                sx={{my: 2, color: '#EEEEEE', display: "block"}}
                            >
                                Pending Restaurants
                            </Button>
                            <Button
                                key={"missing"}
                                onClick={() => {
                                    setMissingInfo(true);
                                }}
                                sx={{my: 2, color: '#EEEEEE', display: "block"}}
                            >
                                Missing Info
                            </Button>
                            </>
                        )}

                    </Box>
                    <Box sx={{mr: '1rem', display: {xs: "none", md: "flex"}}}>
                        <Search onClick={() => setSearchVisible(true)}>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search"
                                value={searchTerm}
                            />
                        </Search>
                    </Box>
                    {/* Login/Logout/User profile */}
                    {user ? (
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={user.displayName} src={user.photoURL}/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: "45px"}}
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setAnchorElUser(false);
                                        setProfileOpen(true);
                                    }}
                                >
                                    <Typography textAlign="center">Profile</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setFavoriteOpen(true);
                                    }}
                                >
                                    <Typography textAlign="center">Favourites</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setWishlistOpen(true);
                                    }}
                                >
                                    <Typography textAlign="center">Interested</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <Box sx={{flexGrow: 0}}>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: "#FFFFFF", // White font color
                                    borderColor: "#FFFFFF", // White border color
                                    "&:hover": {
                                        borderColor: "#FFFFFF", // White border color on hover
                                    },
                                }}
                                onClick={() => setSignInOpen(true)}
                            >
                                Login
                            </Button>
                        </Box>
                    )}
                    <Searchbar visible={searchVisible} onHide={handleSearchToggle} setSearchTerm={setSearchTerm}
                               searchTerm={searchTerm}/>
                </Toolbar>
                <SignIn
                    open={signInOpen}
                    handleClose={() => setSignInOpen(false)}
                />
                <AboutUs open={aboutUsOpen} handleClose={() => setAboutUsOpen(false)}/>
                <ContactUsForm
                    open={contactUsOpen}
                    handleClose={() => setContactUsOpen(false)}
                />
                <RequestRestaurant
                    open={requestRestaurantOpen}
                    handleClose={() => setRequestRestaurantOpen(false)}
                />
                <RestaurantDirectory
                    open={restaurantDirectoryOpen}
                    handleClose={() => setRestaurantDirectoryOpen(false)}
                />
                {pendingRestaurantsOpen && isAdmin && (
                    <PendingRestaurants
                        open={pendingRestaurantsOpen}
                        handleClose={() => setPendingRestaurantsOpen(false)}
                    />
                )}
                {missingInfo && isAdmin && (
                    <MissingInfo
                        open={missingInfo}
                        handleClose={() => setMissingInfo(false)}
                    />
                )}
                {favoriteOpen && (
                    <FavoriteRestaurants
                        open={favoriteOpen}
                        handleClose={() => {
                            setFavoriteOpen(false);
                        }}
                    />
                )}
                {wishlistOpen && (
                    <WishlistRestaurants
                        open={wishlistOpen}
                        handleClose={() => {
                            setWishlistOpen(false);
                        }}
                    />
                )}
                <Profile open={profileOpen} handleClose={() => setProfileOpen(false)}/>
            </AppBar>
            {/* Render Button Menu only on mobile */}
            <Hidden mdUp>
                <BottomMenu>
                    <BottomNavigationAction label="Nearby" icon={<LocationOnIcon/>} onClick={() => {
                        setNearbyRestaurantsOpen(true)
                    }}/>
                    <BottomNavigationAction label="Search" icon={<SearchIcon/>} onClick={() => {
                        setSearchVisible(!searchVisible)
                    }}/>
                </BottomMenu>
                {nearbyRestaurantsOpen && (
                    <NearbyRestaurants
                        open={nearbyRestaurantsOpen}
                        handleClose={() => {
                            setNearbyRestaurantsOpen(false);
                        }}
                    />
                )}
            </Hidden>
        </>
    );
}
