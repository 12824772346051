import {logoutUser, setError, setUser} from "./authSlice";
import "firebase/auth";
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword,
    updateProfile
} from "firebase/auth";
import {auth, db, DB_PATHS} from "../../../containers/Firebase/Firebase";
import {toast} from "react-toastify";
import {doc, getDoc} from "firebase/firestore";

export const register =
    (fullName, email, password, handleSuccess) => async (dispatch) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            updateProfile(userCredential.user, {
                displayName: fullName,
            }).then(() => {
                dispatch(setUser({...userCredential.user, displayName: fullName}));
                handleSuccess();
            });
        } catch (error) {
            dispatch(setError(getAuthErrorMessage(error)));
        }
    };

export const login = (email, password, handleSuccess) => async (dispatch) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );

        let isAdmin = await handleAdmin(userCredential.user);
        const user = {...userCredential.user, isAdmin: isAdmin};
        dispatch(setUser(user));
        handleSuccess();
    } catch (error) {
        dispatch(setError(getAuthErrorMessage(error)));
    }
};

export const logout = (handleSuccess) => async (dispatch) => {
    try {
        await signOut(auth);
        dispatch(logoutUser(null));
        handleSuccess();
    } catch (error) {
        dispatch(setError(getAuthErrorMessage(error)));
    }
};

export const googleSignIn = (handleSuccess) => {
    return async (dispatch) => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            let isAdmin = await handleAdmin(result.user);
            const user = {...result.user, isAdmin: isAdmin};
            dispatch(setUser(user));
            handleSuccess();
        } catch (error) {
            dispatch(setError(getAuthErrorMessage(error)));
        }
    };
};

export const appleSignIn = (handleSuccess) => {
    return async (dispatch) => {
        try {
            const provider = new OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            const result = await signInWithPopup(auth, provider);
            let isAdmin = await handleAdmin(result.user);
            const user = {...result.user, isAdmin: isAdmin};
            dispatch(setUser(user));
            handleSuccess();
        } catch (error) {
            dispatch(setError(getAuthErrorMessage(error)));
        }
    };
};

export const resetPassword = (email, handleSuccess) => {
    return async (dispatch) => {
        try {
            await sendPasswordResetEmail(auth, email);
            handleSuccess();
        } catch (error) {
            toast.error(getAuthErrorMessage(error));
            dispatch(setError(getAuthErrorMessage(error)));
        }
    };
}

export const editPassword = (password, handleSuccess) => {
    return async (dispatch) => {
        try {
            await updatePassword(auth.currentUser, password);
            handleSuccess();
        } catch (error) {
            toast.error(getAuthErrorMessage(error));
            dispatch(setError(getAuthErrorMessage(error)));
        }
    };
}


export const handleAdmin = async (user) => {
    try {
        if (user && Object.values(user).length !== 0) {
            const adminRef = doc(db, DB_PATHS.ADMINS, user.uid);
            const adminSnap = await getDoc(adminRef);
            if (adminSnap.exists()) {
                return true;
            } else {
                return false;
            }
        }
    } catch (error) {
        toast.error("Error fetching admins");
    }
}

const getAuthErrorMessage = (error) => {
    let errorMessage = 'An error occurred. Please try again later.'; // Default message

    // Determine the error code and customize the error message
    switch (error.code) {
        case 'auth/user-disabled':
            errorMessage = 'Your account has been disabled. Please contact support for assistance.';
            break;
        case 'auth/user-not-found':
            errorMessage = 'There is no user corresponding to the provided email. Please check your email and try again.';
            break;
        case 'auth/invalid-email':
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
            errorMessage = 'Incorrect username or password. Please try again.';
            break;
        case 'auth/too-many-requests':
            errorMessage = 'Too many unsuccessful login attempts. Please try again later.';
            break;
        case 'auth/network-request-failed':
            errorMessage = 'A network error occurred. Please check your internet connection and try again.';
            break;
        // Password Reset
        case 'auth/weak-password':
            errorMessage = 'The new password is too weak. Please choose a stronger password.';
            break;
        case 'auth/expired-action-code':
            errorMessage = 'The password reset link has expired. Please request a new one.';
            break;
        case 'auth/invalid-action-code':
            errorMessage = 'The password reset link is invalid. Please request a new one.';
            break;
        case 'auth/requires-recent-login':
            errorMessage = 'This operation is sensitive and requires a recent login. Please log in again.';
            break;
        case 'auth/user-mismatch':
            errorMessage = 'The provided credential does not match the expected user. Please try again.';
            break;
        // Add more cases for other error types as needed
        default:
            // Retain the default message for unknown errors
            break;
    }

    return errorMessage;
}