import { useState, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  DialogActions,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import { register } from "../../redux/features/auth/authThunks";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";

export default function Register({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const dispatch = useDispatch();
  const error = useSelector((state) => state.authReducer.error);

  const handleRegister = () => {
    if (email === "" || password === "" || fullName === "") {
      dispatch(setError("Please fill in all fields"));
      return;
    }

    dispatch(register(fullName, email, password, handleSuccess));
  };

  const handleSuccess = () => {
    toast.success("Registered successfully");
    handleDialogClose();
  };

  const handleDialogClose = () => {
    setEmail("");
    setFullName("");
    setPassword("");
    dispatch(setError(null));
    handleClose();
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="register-dialog-title"
      >
        <DialogTitle id="register-dialog-title">Register</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your details to register.
          </DialogContentText>
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="full-name"
            label="Full Name"
            type="text"
            fullWidth
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            helperText={"Minimum 6 characters long"}
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleRegister}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#1E1E2F" }}
          >
            Register
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
