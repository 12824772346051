import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitedRestaurants: [],
};

const visitedSlice = createSlice({
  name: "visited",
  initialState,
  reducers: {
    setVisited: (state, action) => {
      state.visitedRestaurants = action.payload;
    },
    addToVisited: (state, action) => {
      state.visitedRestaurants.push(action.payload);
    },
    removeFromVisited: (state, action) => {
      state.visitedRestaurants = state.visitedRestaurants.filter(
        (restaurantId) => restaurantId !== action.payload
      );
    },
  },
});

export const { setVisited, addToVisited, removeFromVisited } =
  visitedSlice.actions;
export default visitedSlice.reducer;
