import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishlistRestaurants: [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setWishlist: (state, action) => {
      state.wishlistRestaurants = action.payload;
    },
    addToWishlist: (state, action) => {
      state.wishlistRestaurants.push(action.payload);
    },
    removeFromWishlist: (state, action) => {
      state.wishlistRestaurants = state.wishlistRestaurants.filter(
        (restaurantId) => restaurantId !== action.payload
      );
    },
  },
});

export const { setWishlist, addToWishlist, removeFromWishlist } =
  wishlistSlice.actions;
export default wishlistSlice.reducer;
