import React from 'react';

function InstagramEmbed({ postUrl }) {
    const isMobileDevice = window.innerWidth < 600;
    const minHeight = isMobileDevice ? 600 : 850;

    let trimmedUrl = postUrl.trim(); // Trim the URL
    let embedUrl;

    if (trimmedUrl.includes('?img_index=')) {
        const [baseUrl] = trimmedUrl.split('?'); // Split at the question mark to get the base URL
        trimmedUrl = baseUrl;
    }
    if (trimmedUrl.includes('/p/')) {
        // Regular Instagram post URL
        embedUrl = trimmedUrl.endsWith('/') ? trimmedUrl + "embed" : trimmedUrl + '/embed';
    } else if (trimmedUrl.includes('/reel/')) {
        const reelPathIndex = postUrl.indexOf('/reel/') + '/reel/'.length; // Get the index after '/reel/'
        const nextSlashIndex = postUrl.indexOf('/', reelPathIndex); // Get the index of the next '/'
        const videoId = postUrl.substring(reelPathIndex, nextSlashIndex); // Extract the video ID
        embedUrl = `https://www.instagram.com/reel/${videoId}/embed`;
    } else {
        // Unsupported URL
        return <div>Unsupported Instagram URL</div>;
    }

    return (
        <div>
            <iframe
                title={postUrl}
                src={embedUrl}
                width="100%"
                height={minHeight}
                frameBorder="0"
                scrolling="no"
            />
        </div>
    );
}

export default InstagramEmbed;
