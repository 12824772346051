import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export const DeleteAccount = () => {
    return (
        <Paper elevation={3} style={{ padding: '20px', maxWidth: '400px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
            Delete Account
            </Typography>
            <Typography variant="body1" gutterBottom>
            Are you sure you want to delete your account? This action cannot be undone.
             Please contact{' '}
            <a href="mailto:info@lontratech.com">info@lontratech.com</a>
            {" "} to delete your account.
            </Typography>
        </Paper>
    );
}
