import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  markers: [],
  searchedMarkers: [],
};

const markersSlice = createSlice({
  name: "markers",
  initialState,
  reducers: {
    setMarkers: (state, action) => {
      state.markers = action.payload;
    },
    addToMarkers: (state, action) => {
      state.markers.push(action.payload);
    },
    setSearchedMarkers: (state, action)=> {
      state.searchedMarkers = action.payload;
    },
  },
});

export const { setMarkers, addToMarkers, setSearchedMarkers } = markersSlice.actions;
export default markersSlice.reducer;
