import {doc, getDoc} from "firebase/firestore";
import {db, DB_PATHS} from "../../../containers/Firebase/Firebase";
import {setFavorites} from "./favoritesSlice";
import {toast} from "react-toastify";

export const fetchFavoriteRestaurants = () => async (dispatch, getState) => {
    try {
        const user = getState().authReducer?.user; // Get user from Redux state

        if (user && Object.values(user).length !== 0) {
            const favoriteRef = doc(db, DB_PATHS.FAVORITES, user.uid);
            const favSnap = await getDoc(favoriteRef);

            if (favSnap.exists()) {
                const favoriteRestaurantIds = favSnap.data().favorites;
                dispatch(setFavorites(favoriteRestaurantIds));
            }
        } else {
            dispatch(setFavorites([]));
        }
    } catch (error) {
        console.error("Error fetching favorite restaurants from Firebase:", error);
        toast.error("Error fetching favorite restaurants");
    }
};
