import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CertificatePopup = ({ open, handleClose, certificateUrl }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                Certificate
                <IconButton style={{ position: 'absolute', top: 10, right: 10 }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <img src={certificateUrl} alt="Certificate" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </DialogContent>
        </Dialog>
    );
};