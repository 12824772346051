import {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useForm} from '@formspree/react';
import {toast} from "react-toastify";
import SendIcon from "@mui/icons-material/Send";
import {useSelector} from "react-redux";
import SignIn from "../../SignIn/SignIn";
import Typography from "@mui/material/Typography";

export default function ReelForm({restaurant}) {
    const [url, setUrl] = useState("");
    const [state, handleSubmit] = useForm("xayrdzva");
    const [signInModalOpen, setSignInModalOpen] = useState(false);
    const user = useSelector((state) => state.authReducer.user);

    const handleSendForm = (e) => {
        e.preventDefault();

        if (!user) {
            setSignInModalOpen(true);
            return;
        }

        if (!url) {
            toast.error("Please provide a URL");
            return;
        }

        const formData = {
            userId: user.uid,
            name: user.displayName,
            email: user.email,
            restaurantAddress: restaurant.address,
            restaurantName: restaurant.name,
            url: url
        };

        handleSubmit(formData);
    };

    return (
        <>
            {state.succeeded ? (
                <Typography>
                    Thank you for sending us a reel!
                </Typography>
            ) : (
                <form onSubmit={handleSendForm} style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <TextField
                            id="url"
                            label="Instagram Reel URL"
                            fullWidth
                            required
                            value={url}
                            size="small"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                        <Button size="small" type="submit" color="primary" variant="contained">
                            <SendIcon/>
                        </Button>
                    </div>
                </form>
            )}
            <SignIn open={signInModalOpen} handleClose={() => setSignInModalOpen(false)}/>
        </>
    );
}
