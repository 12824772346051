import React, {Fragment, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Box, IconButton, List, ListItem} from "@mui/material";
import InfoModal from "../InfoModal/InfoModal";
import {useSelector} from "react-redux";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FULL_HALAL } from "../RequestRestaurant/halal_status";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";

export default function RestaurantDirectory({open, handleClose}) {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const user = useSelector((state) => state.authReducer.user);

    const restaurants = useSelector(
        (state) => state.restaurantsReducer.restaurants
    );

    const favorites = useSelector(
        (state) => state.favoritesReducer.favoriteRestaurants
    );
    const visited = useSelector(
        (state) => state.visitedReducer.visitedRestaurants
    );
    const wishlist = useSelector(state => state.wishlistReducer.wishlistRestaurants);

    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (restaurant) => {
        setShowModal(true);
        setModalContent(restaurant);
    };

    // Sorting restaurants alphabetically by name
    const sortedRestaurants = [...restaurants].sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="Restaurant-directory-title"
            >
                <DialogTitle id="Restaurant-directory-title">
                    Restaurant Directory
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom style={{ color: "#A9A9A9", fontWeight: "bold" }}>
                        Total Restaurants: {sortedRestaurants.length}
                    </Typography>
                    <List>
                        {sortedRestaurants.map((restaurant, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    "&:hover": {backgroundColor: "#f0f0f0"},
                                    borderBottom: "1px solid #ccc",
                                }}
                            >
                                <Button
                                    fullWidth
                                    onClick={() => handleItemClick(restaurant)}
                                    startIcon={<RestaurantIcon/>}
                                    sx={{
                                        justifyContent: "flex-start",
                                        textTransform: "none",
                                        textAlign: "left",
                                        padding: "12px",
                                    }}
                                >
                                    <Grid item container direction="column">
                                        <Grid item container direction="row" alignItems='center'>
                                            <Typography
                                                variant="body2"
                                                style={{fontWeight: "bold", marginBottom: "4px"}}
                                                >
                                                {restaurant.name}
                                            </Typography>
                                            {user && (
                                                <Grid item sx={{marginLeft: '0.5rem'}}>
                                                    {favorites.includes(restaurant.id) && (
                                                        <FavoriteIcon
                                                            color="primary"
                                                            style={{fontSize: "1.2rem"}}
                                                        />
                                                    )}
                                                    {visited.includes(restaurant.id) && (
                                                        <CheckCircleSharpIcon
                                                            color="primary"
                                                            style={{fontSize: "1.2rem"}}

                                                        />
                                                    )}
                                                    {wishlist.includes(restaurant.id) && (
                                                        <BookmarkAddIcon
                                                            style={{fontSize: "1.2rem"}}
                                                            color="primary"
                                                        />
                                                    )}
                                                </Grid>
                                            )}

                                        </Grid>
                                        <Typography style={{fontSize: "0.8rem", color: "#666"}}>
                                            {restaurant.address}
                                        </Typography>
                                    </Grid>
                                    {restaurant.menu_status && (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                fontSize: "0.8rem",
                                                fontWeight: "bold",
                                                color:
                                                    restaurant.menu_status === FULL_HALAL
                                                        ? "green"
                                                        : "orange",
                                                marginLeft: "3rem",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {restaurant.menu_status === FULL_HALAL ? "Full Halal" : "Partial Halal"}
                                        </Typography>
                                    )}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                    <Box
                        sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}
                    >
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {showModal && (
                <InfoModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    modalContent={modalContent}
                />
            )}
        </Fragment>
    );
}
