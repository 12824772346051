import React, {Fragment} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {DialogActions, IconButton} from "@mui/material";
import Members from "../FormSpreeForms/ContactUs/Members";
import CloseIcon from "@mui/icons-material/Close";

export default function AboutUs({open, handleClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleDialogClose = () => {
        handleClose();
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="about-us-dialog-title"
            >
                <DialogTitle id="about-us-dialog-title">About Us</DialogTitle>
                <DialogContent>
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography>
                        At The Halal Foodie, we're all about connecting Calgarians with delicious halal options nearby.
                        It's been quite a challenge gathering all this info in one place, but that's why we're here – to
                        make it easy for you to find your next tasty meal. Join us as we explore Calgary's vibrant halal
                        food scene together, one bite at a time!
                    </Typography>
                    <br/>
                    <Typography>
                        An important disclaimer, <strong>we're not here to play the role of a certification
                        board</strong>. Our aim is
                        simple: we gather information from various sources that confirm a restaurant's halal status. We
                        encourage all our customers to trust their instincts and verify any doubts they may have. And if
                        you stumble upon any information that seems blatantly wrong, please don't hesitate to get in
                        touch with us. We're here to help.
                    </Typography>
                    <br/>
                    <Typography>Download our app:</Typography>
                    <div style={{display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://play.google.com/store/apps/details?id=com.thehalalfoodie.www.twa"
                            target="_blank"
                            style={{backgroundColor: '#3b5998'}}
                        >
                            Google Play Store
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://apps.apple.com/us/app/the-halal-foodie/id6503676681"
                            target="_blank"
                            style={{backgroundColor: '#000000'}}
                        >
                            Apple App Store
                        </Button>
                    </div>
                    <Members/>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                        variant="contained"
                        style={{marginTop: "20px"}}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
