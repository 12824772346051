import {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {IconButton, TextField,} from "@mui/material";
import {toast} from "react-toastify";
import {useForm} from '@formspree/react';
import FeedbackBlurb from "./FeedbackBlurb";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export default function ContactUsForm({open, handleClose}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [state, handleSubmit] = useForm("xbjnkykr");

    useEffect(() => {
        if (state.errors) {
            toast.error("Failed to submit message. Please try again later");
        }
    }, [state.errors]);

    const handleSendForm = (e) => {
        e.preventDefault();

        if (!name || !email || !message) {
            toast.error("Please fill in all fields");
            return;
        }

        const formData = {
            name: name,
            email: email,
            message: message
        };

        handleSubmit(formData);
    };

    const handleDialogClose = () => {
        setName("");
        setEmail("");
        setMessage("");
        handleClose();
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="contact-us-dialog-title"
            >
                <DialogTitle id="contact-us-dialog-title">We want to hear from you!</DialogTitle>
                <DialogContent>
                <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <FeedbackBlurb/>
                    {state.succeeded ? ( // If form submission succeeded, display thank you message
                        <>
                            <Box textAlign="center">
                                <Typography gutterBottom>
                                    Thank you for your message, we will get back to you soon!
                                </Typography>
                                <Button onClick={handleDialogClose} color="primary" variant="contained"
                                        style={{marginTop: '20px'}}>
                                    Close
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Fragment>
                            <form onSubmit={handleSendForm}>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Your Name"
                                    fullWidth
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="message"
                                    label="Your Message"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    required
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: 2}}>
                                    <Button onClick={handleDialogClose} color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary" variant="contained"
                                            sx={{backgroundColor: "#1E1E2F", marginLeft: 2}}>
                                        Send Message
                                    </Button>
                                </Box>
                            </form>
                        </Fragment>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

