import React from 'react';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';

function GoogleMapsButton({ googleMapsUri, address }) {
    const openGoogleMaps = () => {
        if (googleMapsUri) {
            window.open(googleMapsUri);
            return;
        }

        const formattedAddress = encodeURIComponent(address);
        window.open(`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`);
    };

    return (
        <IconButton onClick={openGoogleMaps} style={{ color: '#4285F4' }}>
            <DirectionsIcon />
        </IconButton>
    );
}

export default GoogleMapsButton;
