import React, {useEffect, useRef, useState} from 'react';
import {Typography} from '@mui/material';
import lottie from 'lottie-web';
import ReelForm from "../FormSpreeForms/SendReel/ReelForm";

const ReelsPlaceholder = (restaurant) => {
    const animationContainer = useRef(null);
    const [animationInstance, setAnimationInstance] = useState(null);

    useEffect(() => {
        // Fetch the animation data from food.json
        fetch(process.env.PUBLIC_URL + '/food.json')
            .then(response => response.json())
            .then(data => {
                if (animationContainer.current) {
                    const animation = lottie.loadAnimation({
                        container: animationContainer.current,
                        renderer: 'svg', // Choose the renderer: 'svg' or 'canvas'
                        loop: true,
                        autoplay: true,
                        animationData: data,
                    });
                    setAnimationInstance(animation);
                }
            })
            .catch(error => console.error('Error fetching animation data:', error));

        // Cleanup function to destroy the animation instance when component unmounts
        return () => {
            if (animationInstance) {
                animationInstance.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Typography variant="h5" gutterBottom style={{marginTop: "20px"}}>
                Content Coming Soon!
            </Typography>
            <Typography gutterBottom>
                Send us your reels to be featured
            </Typography>
            <ReelForm restaurant={restaurant.restaurant}/>
            <div ref={animationContainer}></div>
        </>
    );
};

export default ReelsPlaceholder;
