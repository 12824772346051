import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export const PrivacyNotice = () => {
  return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '600px', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Privacy Notice
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Information We Collect:</strong>
        <ul>
          <li>When you use our app, we may collect certain information, such as your email address</li>
          <li>We may also collect information about your usage of the app, such as the features you use and how often you use them.</li>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>How We Use Your Information:</strong>
        <ul>
          <li>We use the information we collect to provide and improve our app, to communicate with you, and to personalize your experience.</li>
          <li>We may also use your information to analyze usage patterns and trends, and to comply with legal obligations.</li>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Sharing Your Information:</strong>
        <ul>
          <li>We may share your information in response to legal requests or to protect our rights and interests.</li>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Contact Us:</strong>
        If you have any questions or concerns about our Privacy Notice or our handling of your information, please contact us at <a href="mailto:info@lontratech.com">info@lontratech.com</a>.
      </Typography>
    </Paper>
  );
};

