import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import {toast} from "react-toastify";
import {resetPassword} from "../../redux/features/auth/authThunks";
import {useDispatch} from "react-redux";

const ForgotPassword = ({open, onClose}) => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const handleResetPassword = () => {
        dispatch(resetPassword(email, handleSuccess));
    };

    const handleSuccess = () => {
        toast.success("Password reset email sent. Please check your inbox.");
        onClose();
    };

    const handleOnClose = () => {
        setEmail("");
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Enter your email address to receive a password reset link.
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleResetPassword} color="primary">
                    Reset Password
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ForgotPassword;
