import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Button, Divider} from "@mui/material";
import { setMarkers } from "../../redux/features/markers/markersSlice";

function FilteringLegend() {
    const [showLegend, setShowLegend] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showVisited, setShowVisited] = useState(false);
    const [hideVisited, setHideVisited] = useState(false);
    const [showWishlist, setShowWishlist] = useState(false);
    const dispatch = useDispatch();

    const allRestaurants = useSelector(
        (state) => state.restaurantsReducer.restaurants
    );
    const favoritedRestaurants = useSelector(
        (state) => state.favoritesReducer.favoriteRestaurants
    );
    const visitedRestaurants = useSelector(
        (state) => state.visitedReducer.visitedRestaurants
    );
    const wishlistRestaurants = useSelector(state => state.wishlistReducer.wishlistRestaurants);
    const searchedMarkers = useSelector((state) => state.markersReducer.searchedMarkers);

    useEffect(() => {
        filterMarkers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFavorites, showVisited, hideVisited, showWishlist]);

    const filterMarkers = () => {
        let filteredMarkers = searchedMarkers ? searchedMarkers : allRestaurants;

        if (showFavorites) {
            filteredMarkers = filteredMarkers.filter((restaurant) =>
                favoritedRestaurants.includes(restaurant.id)
            );
        }
        if (showWishlist) {
            filteredMarkers = filteredMarkers.filter((restaurant) =>
                wishlistRestaurants.includes(restaurant.id)
            );
        }
        if (showVisited) {
            filteredMarkers = filteredMarkers.filter((restaurant) =>
                visitedRestaurants.includes(restaurant.id)
            );
        }

        if (hideVisited) {
            filteredMarkers = filteredMarkers.filter(
                (restaurant) => !visitedRestaurants.includes(restaurant.id)
            );
        }

        dispatch(setMarkers(filteredMarkers));
    };

    return (
        <div style={{position: "fixed", bottom: "10px", left: "10px", zIndex: 999}}>
            <Button
                variant="contained"
                onClick={() => setShowLegend(!showLegend)}
            >
                {showLegend ? "Hide Filters" : "Filters"}
            </Button>
            {showLegend && (
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                    }}
                >
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showFavorites}
                                    onChange={(e) => setShowFavorites(e.target.checked)}
                                    color="primary"
                                    name="favorites"
                                />
                            }
                            label="Show Favorites Only"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showVisited}
                                    onChange={(e) => setShowVisited(e.target.checked)}
                                    color="primary"
                                    name="visited"
                                />
                            }
                            label="Show Visited Only"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showWishlist}
                                    onChange={(e) => setShowWishlist(e.target.checked)}
                                    color="primary"
                                    name="interested"
                                />
                            }
                            label="Show Interested Only"
                        />
                    </div>
                    <Divider sx={{borderBottom: "1px solid black"}}/>
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hideVisited}
                                    onChange={(e) => setHideVisited(e.target.checked)}
                                    color="primary"
                                    name="hideVisited"
                                />
                            }
                            label="Hide Visited"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default FilteringLegend;
