import { Tuple, combineReducers, configureStore  } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import favoritesReducer from "./features/favorites/favoritesSlice";
import restaurantsReducer from "./features/restaurants/restaurantsSlice";
import visitedReducer from "./features/visited/visitedSlice";
import wishlistReducer from "./features/wishlist/wishlistSlice";
import markersReducer from "./features/markers/markersSlice";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { thunk } from "redux-thunk";

const rootReducer = combineReducers({
  authReducer: authReducer,
  favoritesReducer: favoritesReducer,
  restaurantsReducer: restaurantsReducer,
  visitedReducer: visitedReducer,
  wishlistReducer: wishlistReducer,
  markersReducer: markersReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['favorites', 'restaurants', 'visited', 'wishlist', 'markers'], // Exclude the 'favorites', 'restaurants', 'visited', 'wishlist' reducer from persistence
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple(thunk),
});

export const persistor = persistStore(store);
