import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import { deleteUser } from "firebase/auth";
import {useDispatch} from "react-redux";
import { auth } from "../../containers/Firebase/Firebase";
import { logoutUser } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { useState } from "react";

export const DeleteUserModal = ({open, handleClose}) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");

    const handleDeleteAccount = async () => {
        const currentUser = auth.currentUser;

        deleteUser(currentUser).then(() => {
            // User deleted.
            dispatch(logoutUser());
            toast.success("Successfully deleted account");
          }).catch((error) => {
            if (error.code === "auth/requires-recent-login") {
                setErrorMessage("You must re-authenticate to delete your account. Please log out and log back in to delete your account.");
            } else {
                setErrorMessage("An error occurred while deleting your account");
            }
            console.error("Error deleting account", error);
          });
    }

    const handleCloseDialog = () => {
        setErrorMessage("");
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant='body2' color='error'>{errorMessage}</Typography>
                </Box>
                <Typography variant='h6'>Are you sure you want to delete your account? This action cannot be undone.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} variant="contained" color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteAccount} variant="contained" color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};