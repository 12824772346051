import {styled} from "@mui/material/styles";
import {Paper} from "@mui/material";

export const SearchResultPaper = styled(Paper)(({theme}) => ({
    position: "absolute",
    top: "calc(100% + 4px)", // 4px to cover the border radius of the search input
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    zIndex: 1,
    maxHeight: 200,
    overflowY: "auto",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
    "&::-webkit-scrollbar": {
        width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: theme.palette.primary.dark,
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.background.default,
        borderRadius: "4px",
    },
}));