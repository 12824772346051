import * as React from "react"
import {useEffect, useState} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import {
    Autocomplete,
    Button,
    Chip,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    styled,
    TextField
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {halalStates} from "../RequestRestaurant/halal_status"
import {collection, deleteDoc, deleteField, doc, getDocs, updateDoc} from "firebase/firestore"
import DeleteIcon from '@mui/icons-material/Delete';
import {db, DB_PATHS} from "../../containers/Firebase/Firebase"
import {toast} from "react-toastify"
import {setRestaurants} from "../../redux/features/restaurants/restaurantsSlice"
import {setMarkers} from "../../redux/features/markers/markersSlice"
import {useDispatch} from "react-redux"
import {all_tags} from "../helpers/all_tags"
import InfoModal from "../InfoModal/InfoModal";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage"
import {v4 as uuidv4} from 'uuid';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjust the width as needed
    maxWidth: 500, // Set a maximum width
    maxHeight: "80vh", // Set a higher maximum height
    overflowY: "auto", // Enable vertical scrolling when content exceeds the maximum height
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
}
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const EditRestaurant = ({open, handleClose, restaurant, handleCloseAll}) => {
    const [editableContent, setEditableContent] = useState(restaurant);
    const [previewOpen, setPreviewOpen] = useState(false);
    const dispatch = useDispatch();
    const [imageUpload, setImageUpload] = useState(null);
    const [imagePreview, setPreviewImage] = useState(null);
    const storage = getStorage();

    useEffect(() => {
        setEditableContent(restaurant);
        setImageUpload(restaurant?.certificate);
        setPreviewImage(restaurant?.certificate);
    }, [restaurant])

    const handleInputChange = (event, field) => {
        setEditableContent({
            ...editableContent,
            [field]: event.target.value,
        })
    }

    const handlePreview = () => {
        setPreviewOpen(true);
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImageUpload(file);

        // Preview the image
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewImage(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const uploadImageToStorage = async (editableContent) => {
        if (!imageUpload) return null;
        if (editableContent.certificate === imageUpload) return editableContent.certificate;

        return new Promise((resolve, reject) => {
            let uuid = uuidv4();
            const storageRef = ref(storage, `certificates/${restaurant.name + uuid}`);
            const uploadTask = uploadBytesResumable(storageRef, imageUpload);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Handle upload progress if needed
                },
                (error) => {
                    console.error("Image failed to upload", error);
                    reject(error);
                },
                () => {
                    // Upload complete, get download URL
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then((downloadURL) => {
                            resolve(downloadURL);
                        })
                        .catch((error) => {
                            console.error("Error getting download URL", error);
                            reject(error);
                        });
                }
            );
        });
    };

    const handleSaveChanges = async () => {
        if (
            !editableContent.name ||
            !editableContent.address ||
            !editableContent.latitude ||
            !editableContent.longitude
        ) {
            toast.error("Please fill in all required fields");
            return;
        }

        let updatedContent = {
            name: editableContent.name,
            address: editableContent.address,
            latitude: parseFloat(editableContent.latitude),
            longitude: parseFloat(editableContent.longitude),
            menu_status: editableContent.menu_status,
            website: editableContent.website,
            reels: editableContent.reels,
            tags: editableContent.tags,
            description: editableContent.description,
            certificate: editableContent.certificate,
            placeId: editableContent.placeId,
        }

        try {
            let downloadURL = await uploadImageToStorage(editableContent);

            updatedContent = {
                ...updatedContent,
                certificate: downloadURL
            }

            // If the uploaded image is removed, delete the original certificate from storage
            if ((!imageUpload && restaurant.certificate) || (restaurant.certificate && downloadURL !== restaurant.certificate)) {
                const storageRef = ref(storage, restaurant.certificate);
                await deleteObject(storageRef);
            }

            const restarantRef = doc(db, DB_PATHS.RESTAURANTS, restaurant.id);

            await updateDoc(restarantRef, {
                ...updatedContent,
            })

            if (!downloadURL) {
                await updateDoc(restarantRef, {
                    certificate: deleteField()
                });
            }

            toast.success(`Restaurant updated successfully`);
            refetchRestaurants();
            handleCloseAll();
        } catch (error) {
            toast.error(`Error uploading document  `);
            console.error(`Error uploading document `, error);
        }
    }

    const refetchRestaurants = async () => {
        getDocs(collection(db, DB_PATHS.RESTAURANTS)).then((snapshot) => {
            const restaurantData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }))
            dispatch(setRestaurants(restaurantData))
            dispatch(setMarkers(restaurantData))
        })
    }

    const handleDeleteRestaurant = async () => {
        if (window.confirm("Are you sure you want to delete this restaurant?")) {
            await deleteDoc(doc(db, DB_PATHS.RESTAURANTS, restaurant.id));
            refetchRestaurants();
            toast.success(`Restaurant deleted successfully`);
            handleCloseAll();
        }
    }

    const handleAddressToLatLng = async () => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    editableContent.address
                )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();

            if (data.results && data.results.length > 0) {
                const {lat, lng} = data.results[0].geometry.location;
                const formattedAddress = data.results[0].formatted_address;

                setEditableContent(prevState => ({
                    ...prevState,
                    address: formattedAddress,
                    latitude: lat,
                    longitude: lng
                }));
            } else {
                throw new Error("No results found for the address: " + data.error_message);
            }
        } catch (error) {
            toast.error(`Error fetching coordinates: ${error.message}`);
            console.error("Error fetching coordinates:", error);
        }
    };

    const removeImage = () => {
        setImageUpload(null);
        setPreviewImage(null);
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" gutterBottom>
                    {editableContent?.name}
                </Typography>
                <IconButton
                    style={{position: "absolute", top: 10, right: 10}}
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            required
                            fullWidth
                            value={editableContent?.name || ""}
                            onChange={(e) => handleInputChange(e, "name")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address"
                            fullWidth
                            required
                            value={editableContent?.address || ""}
                            onChange={(e) => handleInputChange(e, "address")}
                            InputProps={{
                                endAdornment: (
                                    <Button onClick={handleAddressToLatLng} color="primary">
                                        Get Lat/Lng
                                    </Button>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Latitude"
                            fullWidth
                            required
                            value={editableContent?.latitude || ""}
                            onChange={(e) => handleInputChange(e, "latitude")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Longitude"
                            fullWidth
                            required
                            value={editableContent?.longitude || ""}
                            onChange={(e) => handleInputChange(e, "longitude")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="menu-status-label">Menu Status</InputLabel>
                        <Select
                            labelId="menu-status-label"
                            required
                            value={editableContent?.menu_status || ""}
                            onChange={(e) => handleInputChange(e, "menu_status")}
                            fullWidth
                        >
                            {halalStates.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            fullWidth
                            value={editableContent?.description || ""}
                            onChange={(e) => handleInputChange(e, "description")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Website"
                            fullWidth
                            value={editableContent?.website || ""}
                            onChange={(e) => handleInputChange(e, "website")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Reels"
                            fullWidth
                            value={editableContent?.reels || []}
                            onChange={(e) => {
                                setEditableContent({
                                    ...editableContent,
                                    reels: [e.target.value],
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            sx={{mt: "0.5rem"}}
                            options={all_tags.map((option) => option)}
                            freeSolo
                            value={editableContent?.tags || []}
                            onChange={(event, newValue) => {
                                setEditableContent({
                                    ...editableContent,
                                    tags: newValue,
                                })
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({index})}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Tags"/>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Get Place ID from <Link
                            target="_blank"
                            href={"https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"}>here</Link>
                        </Typography>
                        <TextField
                            label="Place ID"
                            fullWidth
                            value={editableContent?.placeId || ""}
                            onChange={(e) => handleInputChange(e, "placeId")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon/>}
                        >
                            Upload Certificate
                            <VisuallyHiddenInput
                                type="file"
                                onChange={handleImageUpload}
                            />
                        </Button>
                        {imageUpload && <Typography variant='subtitle1'>{imageUpload?.name}</Typography>}
                        {imagePreview && (
                            <Box sx={{position: 'relative'}}>
                                <img
                                    src={imagePreview}
                                    alt="Preview"
                                    style={{width: '8rem', height: '8rem', objectFit: 'contain'}}
                                />
                                <IconButton sx={{position: 'absolute', top: 0, left: '8rem'}} onClick={removeImage}
                                            size="small">
                                    <CloseIcon/>
                                </IconButton>
                            </Box>

                        )}

                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleSaveChanges}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Update Restaurant
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handlePreview}
                            variant="contained"
                            color="secondary"
                            fullWidth
                        >
                            PREVIEW
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleDeleteRestaurant}
                            variant="contained"
                            startIcon={<DeleteIcon/>}
                            color="error"
                            fullWidth
                        >
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
                {previewOpen && (
                    <InfoModal
                        open={previewOpen}
                        handleClose={() => setPreviewOpen(false)}
                        modalContent={editableContent}
                        previewMode={true}
                    />
                )}
            </Box>
        </Modal>
    )
}
