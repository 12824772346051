import {toast} from "react-toastify";

export const toastNotification = ({title, body}) => {
    // Implement your toast notification logic
    toast.info(`${title} - ${body}`);
};

export const sendNativeNotification = ({title, body}) => {
    // Implement your native notification logic
    console.log("Background Notification");
    console.log(`Native Notification: ${title} - ${body}`);
};