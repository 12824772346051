import {doc, getDoc} from "firebase/firestore";
import {db, DB_PATHS} from "../../../containers/Firebase/Firebase";
import {setWishlist} from "./wishlistSlice";
import {toast} from "react-toastify";


export const fetchWishlistRestaurants = () => async (dispatch, getState) => {
    try {
        const user = getState().authReducer?.user; // Get user from Redux state

        if (user && Object.values(user).length !== 0) {
            const wishlistRef = doc(db, DB_PATHS.WISHLIST, user.uid);
            const wishlistSnap = await getDoc(wishlistRef);

            if (wishlistSnap.exists()) {
                const wishlistRestaurantIds = wishlistSnap.data().wishlist;
                dispatch(setWishlist(wishlistRestaurantIds));
            }
        } else {
            dispatch(setWishlist([]));
        }
    } catch (error) {
        console.error("Error fetching wishlist restaurants from Firebase:", error);
        toast.error("Error fetching interested restaurants");
    }
};
