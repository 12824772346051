import {IconButton} from "@mui/material";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import * as PropTypes from "prop-types";
import * as React from "react";

UserIcons.propTypes = {
    onClickVisited: PropTypes.func,
    visited: PropTypes.any,
    onClickFavorite: PropTypes.func,
    favorite: PropTypes.any,
    onClickInterested: PropTypes.func,
    interested: PropTypes.any
};

const greyCircleStyles = {
    backgroundColor: '#bdbdbd', // Grey background color
    borderRadius: '50%', // To make it a circle
};

const labelStyles = {
    fontSize: '12px'
};

export function UserIcons(props) {
    return <div
        style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            paddingLeft: "16px"
        }}
    >
        <div style={{textAlign: "center", marginLeft: "1.25rem", marginRight: "1.25rem"}}>
            <IconButton
                onClick={props.onClickVisited}
                style={{...greyCircleStyles, margin: "5px"}}
            >
                <CheckCircleSharpIcon
                    color={props.visited ? "primary" : "action"}
                />
            </IconButton>
            <Typography style={{...labelStyles}}>Visited</Typography>
        </div>
        <div style={{textAlign: "center", marginLeft: "1.25rem", marginRight: "1.25rem"}}>
            <IconButton
                onClick={props.onClickFavorite}
                style={{...greyCircleStyles, margin: "5px"}}
            >
                <FavoriteIcon
                    color={props.favorite ? "primary" : "action"}
                />
            </IconButton>
            <Typography style={{...labelStyles}}>Favorite</Typography>
        </div>
        <div style={{textAlign: "center", marginLeft: "1.25rem", marginRight: "1.25rem"}}>
            <IconButton
                onClick={props.onClickInterested}
                style={{...greyCircleStyles, margin: "5px"}}
            >
                <BookmarkAddIcon
                    color={props.interested ? "primary" : "action"}
                />
            </IconButton>
            <Typography style={{...labelStyles}}>Interested</Typography>
        </div>
    </div>;
}