import React from 'react';
import {BottomNavigation} from '@mui/material';

const BottomMenu = ({children}) => {
    return (
        <BottomNavigation
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                borderTop: '1px solid #dddddd',
                zIndex: 1000,
                backgroundColor: '#1E1E2F',
                '& .MuiBottomNavigationAction-root': {
                    color: '#fff', // Set the color of the icons and text to white
                },
            }}
            showLabels
        >
            {children}
        </BottomNavigation>
    );
};

export default BottomMenu;
