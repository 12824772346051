import React, { useCallback, useEffect, useState, useRef } from "react";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import InfoModal from "../InfoModal/InfoModal";
import { db, DB_PATHS } from "../../containers/Firebase/Firebase";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { fetchFavoriteRestaurants } from "../../redux/features/favorites/favoriteThunks";
import { setNearbyRestaurants, setRestaurants } from "../../redux/features/restaurants/restaurantsSlice";
import FilteringLegend from "../FilteringLegend/FilteringLegend";
import { fetchVisitedRestaurants } from "../../redux/features/visited/visitedThunks";
import { fetchWishlistRestaurants } from "../../redux/features/wishlist/wishlistThunks";
import { setMarkers } from "../../redux/features/markers/markersSlice";
import { Backdrop, Box, IconButton, Typography } from "@mui/material";
import PinDropIcon from '@mui/icons-material/PinDrop';
import ResetIcon from '@mui/icons-material/RestartAlt';
import { Helmet } from "react-helmet";

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 4.5rem)",
};

let worker;

function GoogleMapComponent() {
    const [userLocation, setUserLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [mapCenter, setMapCenter] = useState({ lat: 51.05, lng: -114.0719 }); // Default center
    const [isEditMode, setIsEditMode] = useState(false); // Track edit mode
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [mapBounds, setMapBounds] = useState(null); // State for map bounds
    const [filteredMarkers, setFilteredMarkers] = useState([]);
    const user = useSelector((state) => state.authReducer.user);
    const restaurants = useSelector((state) => state.restaurantsReducer.restaurants);
    const favoritedRestaurants = useSelector(
        (state) => state.favoritesReducer.favoriteRestaurants
    );
    const visitedRestaurants = useSelector(
        (state) => state.visitedReducer.visitedRestaurants
    );
    const wishlistRestaurants = useSelector(state => state.wishlistReducer.wishlistRestaurants);
    const markers = useSelector((state) => state.markersReducer.markers);

    const dispatch = useDispatch();
    const mapRef = useRef(null); // Ref for GoogleMap

    useEffect(() => {
        worker = new Worker(new URL('./markerWorker.js', import.meta.url));
        worker.onmessage = function (e) {
            setFilteredMarkers(e.data);
        };

        return () => {
            worker.terminate();
        };
    }, []);

    const mapOptions = {
        minZoom: 5,
        mapTypeControl: false, // Remove map type controls
        fullscreenControl: false, // Remove fullscreen control
        streetViewControl: false, // Remove street view control
        gestureHandling: "greedy", // Enable gesture handling
        mapId: '49ae42fed52588c3', // Custom map style
        zoomControl: window.innerWidth > 768, // Disable zoomControl for viewport widths less than or equal to 768px
        draggable: isEditMode ? false : true, // Change cursor based on edit mode
    };

    const getMapsIcon = (marker) => {
        let mapObj = {};
        if (wishlistRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/wish-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(40, 40);
        } else if (
            favoritedRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/fav-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(40, 40);
        } else if (
            visitedRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/visited-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(30, 30);
        } else {
            mapObj.url = "/icon.png";
            mapObj.scaledSize = new window.google.maps.Size(30, 30);
        }

        return mapObj;
    };

    useEffect(() => {
        getDocs(collection(db, DB_PATHS.RESTAURANTS)).then((snapshot) => {
            const restaurantData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            dispatch(setRestaurants(restaurantData));
            dispatch(setMarkers(restaurantData));
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchFavoriteRestaurants());
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(fetchVisitedRestaurants());
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(fetchWishlistRestaurants());
    }, [dispatch, user]);

    useEffect(() => {
        const pathParts = window.location.pathname.split('/');
        if (pathParts[1] === 'restaurant' && pathParts[2]) {
            const restaurantId = pathParts[2];
            // Fetch restaurant data based on the restaurantId
            const restaurant = restaurants.find((restaurant) => restaurant.id === restaurantId);
            if (restaurant) {
                setModalContent(restaurant);
                setShowModal(true);
            }
        }
    }, [restaurants]);

    const handleNearbyRestaurants = useCallback(async (lat, long) => {
        const sortedRestaurants = restaurants
            .map(restaurant => ({
                ...restaurant,
                distance: calculateDistance(lat, long, restaurant.latitude, restaurant.longitude)
            }))
            .sort((a, b) => a.distance - b.distance);

        dispatch(setNearbyRestaurants(sortedRestaurants));
    }, [dispatch, restaurants]);

    useEffect(() => {
        // Check if Geolocation is available
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setMapCenter({ lat: latitude, lng: longitude });
                setUserLocation({ lat: latitude, lng: longitude }); // Set user's location
                handleNearbyRestaurants(latitude, longitude);
                return;
            });
        }
        dispatch(setNearbyRestaurants([]));
    }, [restaurants, dispatch, handleNearbyRestaurants]);

    // Function to calculate the distance between two coordinates using the Haversine formula
    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the earth in km
        const dLat = (lat2 - lat1) * Math.PI / 180;  // deg2rad below
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            0.5 - Math.cos(dLat) / 2 +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            (1 - Math.cos(dLon)) / 2;

        return R * 2 * Math.asin(Math.sqrt(a)); // Distance in km
    }

    const handleMarkerClick = (marker) => {
        setModalContent(marker);
        setHoveredMarker(null);
        setShowModal(true);
    };

    const handleMapClick = (e) => {
        const clickedLocation = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        setUserLocation(clickedLocation);
        setIsEditMode(false); // Exit edit mode after setting location
        handleNearbyRestaurants(clickedLocation.lat, clickedLocation.lng);
    };

    const handleMarkerMouseOver = (marker) => {
        setHoveredMarker(marker);
    };

    const handleMarkerMouseOut = () => {
        setHoveredMarker(null);
    };

    const handleResetLocation = () => {
        setUserLocation(null);

        // Check if Geolocation is available
        if (navigator.geolocation) {
            console.log("Geolocation is available", navigator.geolocation)
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setMapCenter({ lat: latitude, lng: longitude });
                setUserLocation({ lat: latitude, lng: longitude }); // Set user's location
                handleNearbyRestaurants(latitude, longitude);
                setIsEditMode(false);
                return;
            });
        }
        setMapCenter({ lat: 51.05, lng: -114.0719 }); // Default center
        dispatch(setNearbyRestaurants([]));
        setIsEditMode(false);
    }

    const updateBounds = () => {
        if (mapRef.current) {
            const bounds = mapRef.current.getBounds();
            if (bounds) {
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                setMapBounds({
                    north: ne.lat(),
                    south: sw.lat(),
                    east: ne.lng(),
                    west: sw.lng()
                });
            }
        }
    };

    useEffect(() => {
        if (mapBounds) {
            worker.postMessage({ markers, bounds: mapBounds });
        }
    }, [mapBounds, markers]);

    return (
        <>
            {showModal ? (
                <Helmet>
                    <meta property="og:title" content={modalContent.name} />
                </Helmet>
            ) : <Helmet>
                <meta property="og:title" content={"the Halal Foodie"} />
            </Helmet>}
            <Backdrop
                sx={{
                    color: '#fff',
                    pointerEvents: 'none',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }}
                open={isEditMode}
                onClick={() => setIsEditMode(false)}
            >
                <Box>
                    <Typography>
                        Click on the map to set your new location
                    </Typography>
                    <IconButton
                        sx={{
                            "&:hover": { backgroundColor: "#28283f" },
                            backgroundColor: '#1E1E2F',
                            pointerEvents: "auto",
                            margin: '1rem 0 0 8rem'
                        }}
                        onClick={() => handleResetLocation()}>
                        <ResetIcon sx={{ fontSize: '2rem', color: '#FFFFFF' }} />
                    </IconButton>
                </Box>
            </Backdrop>
            <FilteringLegend markers={filteredMarkers} />
            <div style={{ position: "fixed", top: "5rem", right: "0.5rem", zIndex: 999 }}>
                <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    <PinDropIcon sx={{ fontSize: '2rem', color: '#B02525' }} />
                </IconButton>
            </div>
            <div style={{ cursor: "pointer", width: "100%", marginTop: '4.5rem', height: "calc(100vh - 4.5rem)" }}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapCenter}
                    zoom={11.5}
                    options={mapOptions}
                    onBoundsChanged={updateBounds} // Update bounds when map bounds change
                    onLoad={map => mapRef.current = map} // Assign the map instance to the ref
                    onClick={isEditMode ? handleMapClick : null} // Attach click event listener only in edit mode
                >
                    <MarkerClusterer minimumClusterSize={5}>
                        {(clusterer) =>
                            filteredMarkers.map((marker) => (
                                <Marker
                                    key={marker.id}
                                    position={{
                                        lat: marker.latitude,
                                        lng: marker.longitude,
                                    }}
                                    onClick={() => handleMarkerClick(marker)}
                                    onMouseOver={() => handleMarkerMouseOver(marker)}
                                    onMouseOut={handleMarkerMouseOut}
                                    icon={getMapsIcon(marker)}
                                    clusterer={clusterer}
                                />
                            ))
                        }
                    </MarkerClusterer>
                    {hoveredMarker && (
                        <InfoWindow
                            position={{ lat: hoveredMarker.latitude, lng: hoveredMarker.longitude }}
                            onCloseClick={() => setHoveredMarker(null)}
                            options={{
                                pixelOffset: { width: 0, height: -30 },
                            }} // Adjust the height as needed
                        >
                            <div>
                                {hoveredMarker.name}
                            </div>
                        </InfoWindow>
                    )}
                    {userLocation && (
                        <Marker
                            position={userLocation}
                            icon={{
                                url: "herePin.png", // You can customize the icon as needed
                                scaledSize: new window.google.maps.Size(60, 60),
                            }}
                        />
                    )}
                </GoogleMap>
                {showModal && (
                    <InfoModal
                        open={showModal}
                        handleClose={() => setShowModal(false)}
                        modalContent={modalContent}
                        fromMap={true}
                    />
                )}
            </div>
        </>
    );
}

export default GoogleMapComponent;
