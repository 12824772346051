import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleMap from '../GoogleMap/GoogleMap';
import Navbar from '../Navbar/Navbar';
import {getAnalytics, logEvent} from "firebase/analytics";
import {CircularProgress, Grid} from "@mui/material";
import Avatar from "@mui/material/Avatar";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1E1E2F', // Set primary color to black
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: '#ffffff', // Set text color of primary contained buttons to white
            },
        },
    },
});

function App() {
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

    // const isForeground = useVisibilityChange();
    // useEffect(() => {
    //     console.log("Setting up notifications useEffect")
    //     // setupNotifications((message) => {
    //     //     console.log("IS FOREGROUND")
    //     //     console.log(isForeground)
    //     //     if (isForeground) {
    //     //         // App is in the foreground, show toast notification
    //     //         toastNotification({
    //     //             title: message.notification.title,
    //     //             description: message.notification.body,
    //     //         });
    //     //     } else {
    //     //         // App is in the background, show native notification
    //     //         sendNativeNotification({
    //     //             title: "New Notification",
    //     //             body: "description",
    //     //         });
    //     //     }
    //     // });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        logEvent(getAnalytics(), 'app load', {page_title: 'Map'});
    }, []);

    // Prevents App from closing on back button press
    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
        };

        window.history.pushState({}, ''); // Push initial state to prevent closing on first back press
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    useEffect(() => {
        const loadGoogleMapsApi = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
            script.async = true;
            script.onload = () => setIsGoogleLoaded(true);
            document.head.appendChild(script);
        };

        if (!window.google) {
            loadGoogleMapsApi();
        } else {
            setIsGoogleLoaded(true);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {!isGoogleLoaded ?
                <Grid container justifyContent="center" alignItems="center" style={{height: '100vh'}}>
                    <Avatar alt="Borgir" src="Borgir-black.png" style={{width: 50, height: 50}}/>
                    <CircularProgress style={{position: 'absolute', width: 100, height: 100}} size={100}/>
                </Grid> :
                <div className="App">
                    <ToastContainer position="top-center"/>
                    <Navbar/>
                    <GoogleMap/>
                </div>
            }
        </ThemeProvider>
    );
}

export default App;
