import Typography from "@mui/material/Typography";
import Members from "./Members";
import Paper from "@mui/material/Paper";


const FeedbackBlurb = () => {
    return (
        <Paper elevation={1} style={{padding: 10, marginBottom: 20}}>
            <Typography variant="body1" gutterBottom>
                We value your opinion! Whether you have a favorite restaurant you'd love to see featured,
                suggestions for improving our service, or any other feedback, we're all ears. Your input helps us
                enhance your experience and tailor our offerings to better suit your needs.
            </Typography>

            <Typography variant="body1" style={{marginTop: 20}}>
                Feel free to drop us a message through our contact form. We're here to listen and eager to hear from
                you!
            </Typography>
            <Members/>
        </Paper>
    );
};

export default FeedbackBlurb;