import React, { useState } from "react";
import { Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Checkbox, FormControlLabel, IconButton, List, ListItem } from "@mui/material";
import InfoModal from "../InfoModal/InfoModal";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FULL_HALAL } from "../RequestRestaurant/halal_status";
import CloseIcon from "@mui/icons-material/Close"
import { isRestaurantOpen } from "../NearbyRestaurants/NearbyRestaurants";

export default function FavoriteRestaurants({ open, handleClose }) {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showOpenOnly, setShowOpenOnly] = useState(false);
  const favoriteRestaurantIds = useSelector(
    (state) => state.favoritesReducer.favoriteRestaurants
  );
  const restaurants = useSelector(
    (state) => state.restaurantsReducer.restaurants
  );
  let nearbyRestaurants = useSelector(
    (state) => state.restaurantsReducer.nearbyRestaurants
)
let favoriteRestaurantsList = nearbyRestaurants.length > 0 ? nearbyRestaurants.filter((restaurant) =>
    favoriteRestaurantIds.includes(restaurant.id)) : restaurants.filter((restaurant) =>
        favoriteRestaurantIds.includes(restaurant.id));

if (showOpenOnly) {
    favoriteRestaurantsList = favoriteRestaurantsList.filter((restaurant) => {
        if (!restaurant.currentOpeningHours || restaurant.currentOpeningHours.length === 0) return false;
        return isRestaurantOpen(restaurant.currentOpeningHours);
    })
}

  const handleDialogClose = () => {
    handleClose();
  };

  const handleCheckboxChange = () => {
    setShowOpenOnly(!showOpenOnly);
  };

  const handleItemClick = (restaurant) => {
    setShowModal(true);
    setModalContent(restaurant);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="favourites-dialog-title"
      >
        <DialogTitle id="favourites-dialog-title">
          Favourite Restaurants ({favoriteRestaurantsList.length})
        </DialogTitle>
        <DialogContent>
            <FormControlLabel
                control={
                <Checkbox
                    checked={showOpenOnly}
                    onChange={handleCheckboxChange}
                    color="primary"
                />
                }
                label="Show Only Open Restaurants"
            />
            <IconButton
                style={{position: "absolute", top: 10, right: 10}}
                onClick={handleDialogClose}
            >
                <CloseIcon />
            </IconButton>
          <List sx={{position: 'relative'}}>
            {favoriteRestaurantsList.map((restaurant, index) => {
                const restaurantIsOpen = restaurant.currentOpeningHours ? isRestaurantOpen(restaurant.currentOpeningHours) : false;

                return (
                <ListItem
                    key={index}
                    disablePadding
                    sx={{
                        padding: '0.5rem 0',
                        "&:hover": { backgroundColor: "#f0f0f0" },
                        borderBottom: "1px solid #ccc",
                    }}
                >
                    <Button
                        fullWidth
                        onClick={() => handleItemClick(restaurant)}
                        startIcon={<StarIcon />}
                        sx={{
                        justifyContent: "flex-start",
                        textTransform: "none",
                        textAlign: "left",
                        padding: "12px",
                        }}
                    >
                        <Grid item container direction={'column'}>
                        <Typography
                            variant="body2"
                            style={{ fontWeight: "bold", marginBottom: "4px" }}
                        >
                            {restaurant.name}
                        </Typography>
                        {restaurant.distance && (
                                    <Typography style={{fontSize: "0.8rem", color: "#666"}}>
                                        Distance: {restaurant.distance.toFixed(2)} km
                                    </Typography>
                                )}
                        </Grid>
                        {restaurant.menu_status && (
                            <Typography
                                variant="body2"
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    color:
                                    restaurant.menu_status === FULL_HALAL
                                        ? "green"
                                        : "orange",
                                    marginLeft: "3rem",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {restaurant.menu_status === FULL_HALAL ? "Full Halal" : "Partial Halal"}
                            </Typography>
                        )}
                        {(restaurant.currentOpeningHours && restaurant.currentOpeningHours.length > 0) && (
                            <>
                                <Typography variant="body2"
                                style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                    color: restaurantIsOpen
                                            ? "green"
                                            : "red",
                                    marginLeft: "3rem",
                                    whiteSpace: "nowrap",
                                }}>
                                    {restaurantIsOpen ? "Open" : "Closed"}
                                </Typography>
                                {restaurantIsOpen && (() => {
                                    const now = new Date();
                                    let currentDay = now.getDay(); // Sunday - Saturday : 0 - 6
                                    let currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes
                                    if (currentDay === 0) currentDay = 6; // Convert Sunday to 6 for indexing
                                    else currentDay -= 1; // Convert to 0-based index

                                    if (currentTime <= 240) {
                                        // If current time is before 4am and restaurant is open, show yesterday's closing time
                                        currentDay = currentDay - 1;
                                        if (currentDay === -1) currentDay = 6; // Convert Sunday to 6 for indexing
                                    }

                                    const openingHoursToday = restaurant.currentOpeningHours[currentDay];
                                    const [, hours] = openingHoursToday.split(": ");

                                    return (
                                    <Typography
                                        sx={{
                                        position: 'absolute',
                                        bottom:'0rem',
                                        right: '0.2rem',
                                        fontSize: "0.8rem",
                                        color: "#666",
                                        textWrap: 'nowrap'}}
                                        variant="paragraph">
                                            {hours}
                                        </Typography>
                                    )
                                })()}
                            </>
                            )}
                    </Button>
                </ListItem>
            )})}
          </List>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button
              onClick={handleDialogClose}
              color="primary"
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {showModal && (
        <InfoModal
          open={showModal}
          handleClose={() => setShowModal(false)}
          modalContent={modalContent}
        />
      )}
    </Fragment>
  );
}
