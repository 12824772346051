import React, {Fragment, useState} from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogAction from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import {useTheme} from "@mui/material/styles"
import {Checkbox, FormControlLabel, List, ListItem} from "@mui/material"
import InfoModal from "../InfoModal/InfoModal"
import {useSelector} from "react-redux"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import RestaurantIcon from "@mui/icons-material/Restaurant"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { FULL_HALAL } from "../RequestRestaurant/halal_status"


export const isRestaurantOpen = (currentOpeningHours) => {
    const now = new Date();
    let currentDay = now.getDay(); // Sunday - Saturday : 0 - 6
    let currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes
    if (!currentOpeningHours || currentOpeningHours.length === 0) return false;
    if (currentDay === 0) currentDay = 6; // Convert Sunday to 6 for indexing
    else currentDay -= 1; // Convert to 0-based index

    const openingHoursToday = currentOpeningHours[currentDay];
    let yesterday = currentDay - 1;
    if (yesterday === -1) yesterday = 6; // Convert Sunday to 6 for indexing
    const yesterdaysOpeningHours = currentOpeningHours[yesterday];

    const [, hours] = openingHoursToday.split(": ");
    const [, yesterdayHours] = yesterdaysOpeningHours.split(": ");

    if (hours.trim() === "Closed") return false;

    const hoursOfOperation = hours.split(",");
    let [opening, closing] = hoursOfOperation[0].split("–");

    const parseTime = (time) => {
        let [hourMin, period] = time.trim().split(" ");
        let [hour, min] = hourMin.split(":").map(Number);

        if (!period) {
            if (hour <= 5 || hour === 12) period = "p.m.";
            else period = "a.m.";
        }

        if (period === "p.m." && hour !== 12) hour += 12;
        if (period === "a.m." && hour === 12) hour = 0;
        return hour * 60 + min; // Convert to minutes
    };

    const isTimeBetween = (opening, closing) => {
        const openingMinutes = parseTime(opening);
        const closingMinutes = parseTime(closing);
        if (closingMinutes < openingMinutes) {
            // Handle overnight hours
            return currentTime >= openingMinutes || currentTime < closingMinutes;
        } else {
            return currentTime >= openingMinutes && currentTime < closingMinutes;
        }
    }

    if (hoursOfOperation.length > 1) {
        // Handle multiple opening hours
        for (let i = 0; i < hoursOfOperation.length; i++) {
            [opening, closing] = hoursOfOperation[i].split("–");
            if (isTimeBetween(opening, closing)) return true;
        }
    }


    const openingMinutes = parseTime(opening);
    const closingMinutes = parseTime(closing);

    //Check if current time is within yesterdays closing time if yesteday wasnt closed
    if (yesterdayHours.trim() !== "Closed") {
        const [, yesterdayClosing] = yesterdayHours.split("–");
        const [, yesterdayClosingperiod] = yesterdayClosing.trim().split(" ");
        const yesterdayClosingMinutes = parseTime(yesterdayClosing);
        if (currentTime < yesterdayClosingMinutes && yesterdayClosingperiod === "a.m.") {
            return true;
        }
    }

    if (closingMinutes < openingMinutes) {
        // Handle overnight hours
        return currentTime >= openingMinutes || currentTime < closingMinutes;
      } else {
        return currentTime >= openingMinutes && currentTime < closingMinutes;
      }
};


export const NearbyRestaurants = ({open, handleClose}) => {
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState({})
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
    let nearbyRestaurants = useSelector(
        (state) => state.restaurantsReducer.nearbyRestaurants
    )
    nearbyRestaurants = nearbyRestaurants.slice(0, 100); // Limit to 100 restaurants
    const [showOpenOnly, setShowOpenOnly] = useState(false);

    const handleDialogClose = () => {
        handleClose()
    }

    const handleItemClick = (restaurant) => {
        setShowModal(true)
        setModalContent(restaurant)
    }

    const handleCheckboxChange = () => {
        setShowOpenOnly(!showOpenOnly);
      };

    let filteredRestaurants = nearbyRestaurants;

    if (showOpenOnly) {
        filteredRestaurants = nearbyRestaurants.filter((restaurant) => {
            if (!restaurant.currentOpeningHours || restaurant.currentOpeningHours.length === 0) return false;
            return isRestaurantOpen(restaurant.currentOpeningHours);
        })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="Nearby-dialog-title"
                maxWidth="lg" // Adjusted maxWidth here
            >
                <DialogTitle
                    id="Nearby-dialog-title"
                    sx={{
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                    }}
                >
                    <Typography variant="h6">Nearby Restaurants</Typography>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: theme.spacing(1),
                            right: theme.spacing(1),
                        }}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>{" "}
                <DialogContent>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showOpenOnly}
                            onChange={handleCheckboxChange}
                            color="primary"
                        />
                        }
                        label="Show Only Open Restaurants"
                    />
                    {filteredRestaurants?.length > 0 ? (
                        <List sx={{position: 'relative'}}>
                            {filteredRestaurants.map((restaurant, index) => {
                                let restaurantIsOpen = restaurant.currentOpeningHours ? isRestaurantOpen(restaurant.currentOpeningHours) : false;

                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            padding: '0.5rem 0',
                                            "&:hover": {backgroundColor: "#f0f0f0"},
                                            borderBottom: "1px solid #ccc",
                                        }}
                                    >
                                        <Button
                                            fullWidth
                                            onClick={() => handleItemClick(restaurant)}
                                            startIcon={<RestaurantIcon/>}
                                            sx={{
                                                justifyContent: "flex-start",
                                                textTransform: "none",
                                                textAlign: "left",
                                                padding: "12px",
                                            }}
                                        >
                                            <Grid item container direction="column">
                                                <Typography
                                                    variant="body2"
                                                    style={{fontWeight: "bold", marginBottom: "4px"}}
                                                >
                                                    {restaurant.name}
                                                </Typography>
                                                <Typography style={{fontSize: "0.8rem", color: "#666"}}>
                                                    Distance: {restaurant.distance.toFixed(2)} km
                                                </Typography>
                                            </Grid>
                                            {restaurant.menu_status && (
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        fontWeight: "bold",
                                                        color:
                                                            restaurant.menu_status === FULL_HALAL
                                                                ? "green"
                                                                : "orange",
                                                        marginLeft: "3rem",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    {restaurant.menu_status === FULL_HALAL
                                                        ? "Full Halal"
                                                        : "Partial Halal"}
                                                </Typography>
                                            )}
                                            {(restaurant.currentOpeningHours && restaurant.currentOpeningHours.length > 0) && (
                                                <>
                                                    <Typography variant="body2"
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        fontWeight: "bold",
                                                        color: restaurantIsOpen
                                                                ? "green"
                                                                : "red",
                                                        marginLeft: "3rem",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                        {restaurantIsOpen ? "Open" : "Closed"}
                                                    </Typography>
                                                    {restaurantIsOpen && (() => {
                                                        const now = new Date();
                                                        let currentDay = now.getDay(); // Sunday - Saturday : 0 - 6
                                                        let currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes
                                                        if (currentDay === 0) currentDay = 6; // Convert Sunday to 6 for indexing
                                                        else currentDay -= 1; // Convert to 0-based index

                                                        if (currentTime <= 240) {
                                                            // If current time is before 4am and restaurant is open, show yesterday's closing time
                                                            currentDay = currentDay - 1;
                                                            if (currentDay === -1) currentDay = 6; // Convert Sunday to 6 for indexing
                                                        }

                                                        const openingHoursToday = restaurant.currentOpeningHours[currentDay];
                                                        const [, hours] = openingHoursToday.split(": ");

                                                        return (
                                                        <Typography
                                                            sx={{
                                                            position: 'absolute',
                                                            bottom:'0rem',
                                                            right: '0.2rem',
                                                            fontSize: "0.8rem",
                                                            color: "#666",
                                                            textWrap: 'nowrap'}}
                                                            variant="paragraph">
                                                                {hours}
                                                            </Typography>
                                                        )
                                                    })()}
                                                </>
                                                )}
                                            {restaurant?.businessStatus === 'CLOSED_PERMANENTLY' && (
                                                <Typography variant="body2"
                                                style={{
                                                    fontSize: "0.8rem",
                                                    fontWeight: "bold",
                                                    color: "red",
                                                    marginLeft: "3rem",
                                                    whiteSpace: "nowrap",
                                                }}>
                                                    Closed
                                                </Typography>
                                            )}
                                        </Button>
                                    </ListItem>
                            )})}
                        </List>
                    ) : (
                        <Typography variant="body1">
                            No nearby restaurants found, please enable sharing your location.
                        </Typography>
                    )
                    }
                </DialogContent>
                <DialogAction>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                        variant="outlined"
                    >
                        Close
                    </Button>
                </DialogAction>
            </Dialog>
            {showModal && (
                <InfoModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    modalContent={modalContent}
                />
            )}
        </Fragment>
    )
}
