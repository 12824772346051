import {AvatarGroup, IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";

function Members() {
    return <div style={{display: "flex", justifyContent: "flex-end", marginTop: 20}}>
        <AvatarGroup max={6}>
            <Tooltip title="Connect with Afshin Rahman on LinkedIn">
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/afshin-rahman/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Afshin Rahman on LinkedIn"
                >
                    <Avatar alt="Afshin Rahman"
                            src="Afshin.jpeg"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Connect with Sadat Islam on LinkedIn">
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/sadat-islam/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Sadat Islam on LinkedIn"
                >
                    <Avatar alt="Sadat Islam"
                            src="Sadat.jpeg"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Connect with Redwan Mohammed on LinkedIn">
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/redwan-m/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Redwan Mohammed on LinkedIn"
                >
                    <Avatar alt="Redwan Mohammed" src="Ridu.PNG"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Connect with Masrur Azmal on LinkedIn">
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/masrur-azmal-850106211/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Masrur Azmal on LinkedIn"
                >
                    <Avatar alt="Masrur Azmal"
                            src="Reshad.jpeg"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Connect with Sammy Patwary on LinkedIn">
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/sammy-patwary/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Sammy Patwary on LinkedIn"
                >
                    <Avatar alt="Sammy Patwary"
                            src="Sammy.jpeg"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Connect with Sayem Ahmed on LinkedIn"}>
                <IconButton
                    component="a"
                    href="https://www.linkedin.com/in/sayem-ahmed-169a1216a/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with Sayem Ahmed on LinkedIn"
                >
                    <Avatar alt="Sayem Ahmed"
                            src="Sayem.jpeg"/>
                </IconButton>
            </Tooltip>
        </AvatarGroup>
    </div>;
}

export default Members;