import React from 'react';

function WebsiteHyperlink({link}) {
    // Remove trailing slash if it exists
    const websiteWithoutProtocol = link.replace(/\/$/, '').replace(/^(https?:\/\/)?(www\.)?/, '');

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            msWordBreak: 'break-all',
            wordBreak: 'break-word',
            hyphens: 'auto'
        }}>
            {websiteWithoutProtocol}
        </a>
    );
}

export default WebsiteHyperlink;
