// restaurantsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restaurants: [],
  nearbyRestaurants: [],
};

const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {
    setRestaurants(state, action) {
      state.restaurants = action.payload;
    },
    addRestaurant(state, action) {
      state.restaurants.push(action.payload);
    },
    removeRestaurant(state, action) {
      state.restaurants = state.restaurants.filter(
        (restaurant) => restaurant.id !== action.payload
      );
    },
    setNearbyRestaurants(state, action) {
        state.nearbyRestaurants = action.payload;
    }
    // Add more actions as needed
  },
});

export const { setRestaurants, addRestaurant, removeRestaurant, setNearbyRestaurants } = restaurantsSlice.actions;
export default restaurantsSlice.reducer;
