import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoriteRestaurants: [],
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setFavorites: (state, action) => {
      state.favoriteRestaurants = action.payload;
    },
    addToFavorites: (state, action) => {
      state.favoriteRestaurants.push(action.payload);
    },
    removeFromFavorites: (state, action) => {
      state.favoriteRestaurants = state.favoriteRestaurants.filter(
        (restaurantId) => restaurantId !== action.payload
      );
    },
  },
});

export const { setFavorites, addToFavorites, removeFromFavorites } =
  favoritesSlice.actions;
export default favoritesSlice.reducer;
