import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getAnalytics} from "firebase/analytics";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {store} from "../../redux/store";
import {logoutUser, setFcmToken} from "../../redux/features/auth/authSlice";
import {toastNotification} from "./notificationHelpers";

const firebaseConfig = {
    apiKey: "AIzaSyAof7Yr70KJ3OkqOxj-WY_hKN8jDfPzJIQ",
    authDomain: "www.thehalalfoodie.com",
    databaseURL: "https://lontra-foods-default-rtdb.firebaseio.com",
    projectId: "lontra-foods",
    storageBucket: "lontra-foods.appspot.com",
    messagingSenderId: "855388539395",
    appId: "1:855388539395:web:f5360888ef047c0e821c36",
    measurementId: "G-32CRG0T4WG",
};

export const DB_PATHS = {
    RESTAURANTS: "restaurants",
    FAVORITES: "favorites",
    VISITED: "visited",
    REQUESTED: "requested_restaurants",
    WISHLIST: "wishlist",
    ADMINS: "admins",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);

export const analytics = getAnalytics(app);


export const messaging = getMessaging(app);

export const setupNotifications = async (user) => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get the FCM token
            const token = await getToken(messaging);
            store.dispatch(setFcmToken(token));
        } else {
            console.log('Notification permission denied.');
        }

        // Handle foreground notifications
        onMessage(messaging, (payload) => {
            console.log('Foreground Message:', payload);
            toastNotification({title: payload.notification.title, body: payload.notification.body});
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};

auth.onAuthStateChanged(async (user) => {
    if (user) {
        try {
            setupNotifications(user);
            // User is signed in
            const idTokenResult = await user.getIdTokenResult();
            const tokenExpirationTime = idTokenResult.expirationTime;

            const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

            if (currentTime >= tokenExpirationTime) {
                // Token expired, dispatch logout action
                store.dispatch(logoutUser());
            }
        } catch (error) {
            console.error("Error fetching token expiration:", error);
        }
    } else {
        // User is signed out
        store.dispatch(logoutUser());
    }
});
