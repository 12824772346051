import React, {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Box, IconButton, List, ListItem} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import {collection, getDocs} from "firebase/firestore";
import {db, DB_PATHS} from "../../containers/Firebase/Firebase";
import PendingRestaurantsModal from "./PendingRestaurantsModal";
import CloseIcon from "@mui/icons-material/Close"

export default function PendingRestaurants({open, handleClose}) {
    const [showModal, setShowModal] = useState(false);
    const [pendingRestaurants, setPendingRestaurants] = useState([]);
    const [modalContent, setModalContent] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (restaurant) => {
        setShowModal(true);
        let content = {
            ...restaurant,
            address: [restaurant.address, restaurant.city, restaurant.province]
        }
        setModalContent(content);
    };
    const getPendingData = async () => {
        //fetch pending restaurants
        getDocs(collection(db, DB_PATHS.REQUESTED)).then((snapshot) => {
            const restaurantData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setPendingRestaurants(restaurantData);
        });
    }
    useEffect(() => {
        getPendingData();
    }, []);

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="wishlist-dialog-title"
            >
                <DialogTitle id="wishlist-dialog-title">
                    Pending Restaurants
                </DialogTitle>
                <DialogContent>
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={handleDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <List>
                        {pendingRestaurants.map((restaurant, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{"&:hover": {backgroundColor: "#f0f0f0"}}}
                            >
                                <Button
                                    fullWidth
                                    onClick={() => handleItemClick(restaurant)}
                                    startIcon={<StarIcon/>}
                                    sx={{justifyContent: "flex-start", textTransform: "none"}}
                                >
                                    {restaurant.name}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                    <Box
                        sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}
                    >
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {showModal && (
                <PendingRestaurantsModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    modalContent={modalContent}
                    getPendingData={getPendingData}
                />
            )}
        </Fragment>
    );
}
