// authSlice.js
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    user: {},
    error: null,
    fcm_token: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.error = null;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        logoutUser: (state) => {
            state.user = null;
        },
        setFcmToken: (state, action) => {
            state.fcm_token = action.payload;
        }
    },
});

export const {setUser, setError, logoutUser, setFcmToken} = authSlice.actions;
export default authSlice.reducer;
