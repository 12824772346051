import {doc, getDoc} from "firebase/firestore";
import {db, DB_PATHS} from "../../../containers/Firebase/Firebase";
import {toast} from "react-toastify";
import {setVisited} from "./visitedSlice";

export const fetchVisitedRestaurants = () => async (dispatch, getState) => {
    try {
        const user = getState().authReducer?.user; // Get user from Redux state

        if (user && Object.values(user).length !== 0) {
            const visitedRef = doc(db, DB_PATHS.VISITED, user.uid);
            const visitedSnap = await getDoc(visitedRef);

            if (visitedSnap.exists()) {
                const visitedRestaurantIds = visitedSnap.data().visited;
                dispatch(setVisited(visitedRestaurantIds));
            }
        } else {
            dispatch(setVisited([]));
        }
    } catch (error) {
        console.error("Error fetching visited restaurants from Firebase:", error);
        toast.error("Error fetching visited restaurants");
    }
};
